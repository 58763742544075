<template>
   <ObiText class="obi-card card" :class="classList" v-bind="attrs" v-on="$listeners">
      <ObiText class="card-header" v-if="$slots.header">
         <div class="m-2">
            <slot name="header" />
         </div>
      </ObiText>

      <vue-loading
         class="col-md-12 card-body p-0 m-2"
         v-if="loading"
         type="bubbles"
         color="#2f71f2"
         :size="{ width: '50px', height: '50px' }"
      ></vue-loading>

      <ObiText class="card-body p-0 m-2" v-if="!loading && !$slots.body">
         <slot />
      </ObiText>

      <ObiText class="card-body p-0" v-if="!loading && $slots.body && !hideBody">
         <slot name="body" />
      </ObiText>

      <ObiText class="card-footer" v-if="!loading && $slots.footer">
         <slot name="footer" />
      </ObiText>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';
import { VueLoading } from 'vue-loading-template';

export default {
   name: 'ObiCard',

   props: {
      selectable: {
         type: Boolean,
         default: false,
      },
      hideBody: {
         type: Boolean,
         default: false,
      },
      loading: {
         type: Boolean,
         default: false,
      },
   },

   components: { VueLoading },

   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      classList() {
         const classList = [];

         if (this.selectable !== null) {
            classList.push('--selectable');
         }

         return classList;
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-card {
   border-radius: 10px;
   background: #fff;
   box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.03);

   &.no-border {
      border-width: 0px !important;
      box-shadow: none !important;

      .card-header {
         padding: 0px !important;
      }
   }
   &.no-background {
      .card-header {
         background-color: transparent !important;
         border-bottom-color: transparent !important;
      }
   }

   .card-body {
      font-size: 15px;
   }
   .card-header {
      padding: 0px;
      background-color: rgba(183, 183, 183, 0.09);
      border-bottom-color: #e1e1e1;
   }

   .card-footer {
      padding: 10px;
      background-color: #f8f8f8;
      border-top-color: transparent;
   }

   &.--selectable {
      cursor: pointer;
   }
}
</style>
